import { useTranslation } from 'react-i18next';

import { SliderFullPage, Text } from '@Components/ui';

import { LoginCredentialsSlideProps } from './LoginCredentialsSlide.props';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const LoginCredentialsSlide = (props: LoginCredentialsSlideProps) => {
  const { t } = useTranslation();

  return (
    <SliderFullPage.Item>
      <SliderFullPage.Head title={t('DOMAIN.AUTHENTICATION.LOGIN.CREDENTIALS.HEADER.TITLE')} />

      <SliderFullPage.Block>
        <Text.Large>
          Dankjewel voor je interesse in deze medewerkersportal. Deze Proof of concept van 'Kom
          Verder’ is vanaf 1 januari 2023 niet meer in gebruik.
        </Text.Large>
      </SliderFullPage.Block>
    </SliderFullPage.Item>
  );
};
