import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import { ApiError, ApiErrorDomain, HandledError } from '@Constants/interfaces';

import { i18n } from './TranslationUtils';

export const isApiError = (error: any): error is AxiosError<ApiError> => {
  return !!error.response?.data.code;
};

export const handleUnknownError = (error: any) => {
  toast.error(i18n.t('COMMON.GLOBALS.UNKNOWN_ERROR'));
  Sentry.captureException(error);
};

export const getApiErrorMessage = (error: HandledError) => {
  const getAdditional = () => {
    if (error.additional) {
      if (Array.isArray(error.additional)) {
        return `.${error.additional[0]}`;
      }

      return `.${error.additional}`;
    }

    return '.DEFAULT';
  };

  return i18n.t(`COMMON.API_ERRORS.${error.domain}.${error.code}${getAdditional()}`, '');
};

export const handleApiError = (error: any, domain: ApiErrorDomain = 'DEFAULT') => {
  if (isApiError(error) && error.response?.data) {
    const domainError = {
      ...error.response?.data,
      domain
    };

    const errorMessage = getApiErrorMessage(domainError);

    if (errorMessage !== '') {
      return domainError;
    }
  }

  handleUnknownError(error);
};
